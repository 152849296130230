var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"approvalchanges","size":"sm-p","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title"},[_vm._v("Confirm")]),_c('a',{staticClass:"close-modal dialog-close-button mr-2"},[_c('i',{staticClass:"fal fa-times fa-lg ",on:{"click":function($event){_vm.removerole = false}}})])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('button-gruppe',{attrs:{"text1":"Yes proceed"},on:{"no":cancel,"yes":function($event){return _vm.tryremoveuser( _vm.activeuser, 'confirm',true)}}})]}}]),model:{value:(_vm.removeuser),callback:function ($$v) {_vm.removeuser=$$v},expression:"removeuser"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" Warning:")]),_vm._v(" This action will remove you as an owner and you will immediately lose access to this settings page. ")]),_c('b-modal',{attrs:{"id":"approvalchanges2","size":"sm-p","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title"},[_vm._v("Confirm")]),_c('a',{staticClass:"close-modal dialog-close-button mr-2"},[_c('i',{staticClass:"fal fa-times fa-lg ",on:{"click":function($event){_vm.removeuser = false}}})])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('button-gruppe',{attrs:{"text1":"Yes proceed"},on:{"no":cancel,"yes":function($event){return _vm.switchrole( _vm.activeuser, _vm.torole, true )}}})]}}]),model:{value:(_vm.removerole),callback:function ($$v) {_vm.removerole=$$v},expression:"removerole"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" Warning:")]),_vm._v(" This action will remove you as an owner and you will immediately lose access to this settings page. If you proceed and later on regret removing yourself, then you will need to ask another owner to add you as owner again. ")]),_c('b-modal',{attrs:{"id":"approvalchanges2","size":"sm-p","no-close-on-backdrop":"","no-close-on-esc":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"modal-title"},[_vm._v("Restore access and add?")]),_c('a',{staticClass:"close-modal dialog-close-button mr-2"},[_c('i',{staticClass:"fal fa-times fa-lg ",on:{"click":function($event){_vm.addnoaccesstolist = false}}})])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('button-gruppe',{attrs:{"text1":"Yes proceed"},on:{"no":cancel,"yes":function($event){return _vm.userselectwarningproceed()}}})]}}]),model:{value:(_vm.addnoaccesstolist),callback:function ($$v) {_vm.addnoaccesstolist=$$v},expression:"addnoaccesstolist"}},[_c('span',{staticClass:"text-danger"},[_vm._v(" Warning:")]),_vm._v(" You are adding a user that has previously had their access to the organization revoked, Adding them will restore their access to the organization as well as any boards and objectives they previously had access to. Do you wish to proceed? ")]),_c('memberslist',{attrs:{"adminrole":_vm.adminrole,"roles":_vm.roles,"users":_vm.combinedusers,"myrole":_vm.myrole,"isactive":_vm.isactive,"numowners":_vm.numowners,"username":_vm.username,"hasremove":_vm.hasremove,"isworking":_vm.isworking,"isadmanaged":_vm.isadmanaged,"includeteams":_vm.includeteams,"listtype":_vm.listtype},on:{"onexpandme":_vm.expandme,"accessrequest":_vm.accessrequest,"tryremoveuser":_vm.tryremoveuser,"switchrole":_vm.switchrole,"resendemail":_vm.resendemail}}),_c('membersearch',{attrs:{"searchtext":_vm.searchtext,"adminrole":_vm.adminrole,"users":_vm.combinedusers,"availableusers":_vm.availableusers,"myrole":_vm.myrole,"isactive":_vm.isactive,"username":_vm.username,"allowadduser":_vm.allowadduser,"alreadyonlist":_vm.alreadyonlist,"sendaninvite":_vm.sendaninvite,"hasnodrop":_vm.hasnodrop,"searchquery":_vm.searchquery,"isworking":_vm.isworking,"isadmanaged":_vm.isadmanaged,"cockpitupgradestatus":_vm.cockpitupgradestatus,"membersmodaltype":_vm.membersmodaltype},on:{"onupdated":_vm.onupdated,"availableusersupdate":_vm.availableusersupdate,"userSelect":_vm.userSelect,"oninviteuser":_vm.inviteuser,"onsearchactive":_vm.searchactive}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }